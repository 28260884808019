'use client'

import { db } from "@/connectors/firebase";
import { Conversation, IMessage } from "@/interfaces/firebase";
import { collection, onSnapshot, or, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { cloneDeep } from "lodash";
import useAuth from "@/hooks/useAuth";

export interface chatsProps {
    [x: string]: Conversation;
}

const MessagesContext = React.createContext({
    messages: {} as chatsProps,
    isLoadingMessages: true as boolean,
    setIsLoadingMessages: (isLoading: boolean) => { },
});

export function useMessages() {
    return useContext(MessagesContext);
}

export function MessagesProvider({ children }: { children: React.ReactNode }) {
    const { currentUser, userLoggedIn } = useAuth()
    const queryClient = useQueryClient()
    const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(true)
    const { data } = useQuery(
        {
            queryKey: ["chats"],
            initialData: () => {
                return queryClient.getQueryData(["chats"]) as chatsProps ?? {}
            },
            staleTime: Infinity
        }
    )
    const [messages, setMessages] = useState<chatsProps>(data)

    useEffect(() => {
        if (data) setMessages(data)
    }, [])

    useEffect(() => {
        if (messages) queryClient.setQueryData(["chats"], messages);
    }, [messages])

    useEffect(() => {
        if (!userLoggedIn) return
        let unsubConversations = () => { };
        try {
            if (currentUser?.id) {
                const conversationsRef = query(
                    collection(db, "conversations"),
                    or(
                        where("participants", "array-contains", currentUser.id),
                        where("isBulletinBoard", "==", true)
                    )
                );
                unsubConversations = onSnapshot(conversationsRef, (conversationSnap) => {
                    let allConversations: Conversation[] = [];
                    const cachedMessages = JSON.parse(JSON.stringify(data))
                    conversationSnap.forEach((doc) => {
                        allConversations.push({ ...(doc.data() as Conversation), id: doc.id, messages: (cachedMessages[doc.id] && cachedMessages[doc.id].messages) || [] });
                    });

                    let allChats: chatsProps = {}

                    allConversations.forEach((conversation) => {
                        let checkConversation = cloneDeep(cachedMessages[conversation.id])
                        if (checkConversation && checkConversation.messages) delete checkConversation.messages
                        let currentConversation = conversation
                        if (currentConversation && currentConversation.messages) delete currentConversation.messages
                        if (JSON.stringify(currentConversation) == JSON.stringify(checkConversation)) {
                            return
                        }
                        allChats[conversation.id] = { ...conversation, messages: (cachedMessages[conversation.id] && cachedMessages[conversation.id].messages) || [] }
                    });
                    setMessages(allChats)

                    // For each conversation, observe its messages
                    allConversations.forEach((conversation) => {
                        const messagesRef = query(
                            collection(db, "conversations", conversation.id, "messages")
                        );

                        onSnapshot(messagesRef, (messagesSnap) => {
                            try {
                                let allMessages: IMessage[] = [];
                                messagesSnap.forEach((messageDoc) => {
                                    allMessages.push({
                                        ...(messageDoc.data() as IMessage),
                                        id: messageDoc.id,
                                    });
                                });
                                // Sort messages by timestamp
                                const sortedMessages = allMessages.sort(
                                    (a, b) => a.timestamp.seconds - b.timestamp.seconds
                                );
                                const cachedMessages = data[conversation.id] && data[conversation.id].messages || [];
                                if (JSON.stringify(sortedMessages) === JSON.stringify(cachedMessages)) {
                                    return;
                                }
                                setMessages((prev) => {
                                    if (!conversation?.id) {
                                        return prev;
                                    }
                                    return {
                                        ...prev,
                                        [conversation.id]: {
                                            ...((prev[conversation.id] ?? conversation)),
                                            messages: sortedMessages
                                        }
                                    }
                                })
                            } catch (error) {
                                console.log(error)
                            }
                        });
                    });
                    setIsLoadingMessages(false)
                });
            }
        } catch (error: any) {
            console.log(error)
        }
        return unsubConversations;
    }, [currentUser?.id, queryClient, userLoggedIn]);

    const value = {
        messages,
        isLoadingMessages,
        setIsLoadingMessages
    }

    return (
        <MessagesContext.Provider value={value}>
            {children}
        </MessagesContext.Provider>
    )
}