"use client"

import React, { createContext, useContext, useState, ReactNode, useEffect, useCallback } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import useAuth from "@/hooks/useAuth";
import { Connection, Leads, Need } from '@/interfaces/firebase';
import { useConnections } from './connectionsContext';
import { chatsProps, useMessages } from './messagesContext';
import { useNeeds } from './needsContext';
import { useLeads } from './LeadsContext';


interface AllNotificationContextProps {
    allNotifications: number;
    messageNotifications: number;
    connectionNotifications: number;
    needsNotifications: number;
}

const AllNotificationContext = createContext<AllNotificationContextProps | undefined>(undefined);

export const useAllNotifications = () => {
    const context = useContext(AllNotificationContext);
    if (!context) {
        throw new Error('useAllNotifications must be used within a AllNotificationProvider');
    }
    return context;
};

export const AllNotificationProvider = ({ children }: { children: ReactNode }) => {
    const [isLoadFirstTime, setIsLoadFirstTime] = useState<boolean>(false);
    const [allNotifications, setAllNotifications] = useState<number>(0);
    const [messageNotifications, setMessageNotifications] = useState<number>(0);
    const [connectionNotifications, setConnectionNotifications] = useState<number>(0);
    const [needsNotifications, setNeedsNotifications] = useState<number>(0);
    const [messagesToCompare, setMessagesToCompare] = useState<chatsProps>({});
    const path = usePathname();
    const searchParams = useSearchParams();
    const { currentUser, userLoggedIn } = useAuth()
    const { messages, isLoadingMessages } = useMessages()
    const { connections } = useConnections()
    const { needs } = useNeeds()
    const { leads } = useLeads()

    useEffect(() => {
        if (userLoggedIn) {
            setIsLoadFirstTime(false)
            setAllNotifications(0)
            setMessageNotifications(0)
            setConnectionNotifications(0)
            setNeedsNotifications(0)
        }
    }, [userLoggedIn])

    useEffect(() => {
        if (!connections.length) return
        let allConnections: Connection[] = []
        for (const connection of connections) {
            allConnections.push(connection.connection)
        }
        const pending = allConnections.filter(f => f.status === "pending" && f.members[0] !== currentUser.id).length
        if (pending) {
            setConnectionNotifications(pending)
        }
        else {
            setConnectionNotifications(0)
        }
    }, [connections])

    useEffect(() => {
        try {
            if (!needs.length) return
            const onlyNeedUserLogged: Need[] = []
            for (const need of needs) {
                if (need.creator == currentUser.id) onlyNeedUserLogged.push(need)
            }
            if (!onlyNeedUserLogged.length) return
            let onlyLeadsReceived: Leads[] = []
            for (const need of onlyNeedUserLogged) {
                let getAllLeads = leads.filter(f => f.needId === need.id)
                if (getAllLeads.length) onlyLeadsReceived = getAllLeads
            }
            let IsNotWasSeen: number = 0
            for (const lead of onlyLeadsReceived) {
                if (!lead.wasSeen) IsNotWasSeen++;
            }
            if (IsNotWasSeen) setNeedsNotifications(IsNotWasSeen)
        } catch (error) {
            console.log(error)
        }
    }, [needs, leads])

    useEffect(() => {
        setAllNotifications(messageNotifications + connectionNotifications + needsNotifications)
    }, [messageNotifications, connectionNotifications, needsNotifications])

    useEffect(() => {
        getAllNotificationMessage()
    }, [messages, isLoadingMessages])

    useEffect(() => {
        if (path.includes("messages")) {
            setMessageNotifications(0)
            if (isLoadingMessages || !Object.values(messages).length) return
            const userMessagesOnly = filterUserContextChats(messages)
            const messagesOtherUserOnly = filterUserMessages(userMessagesOnly)
            setMessagesToCompare(messagesOtherUserOnly)
        }
    }, [path])

    const getAllNotificationMessage = async () => {
        try {
            await waitForSeconds(5)
            if (isLoadingMessages || !Object.values(messages).length) return
            const userMessagesOnly = filterUserContextChats(messages)
            const messagesOtherUserOnly = filterUserMessages(userMessagesOnly)
            if (!messagesToCompare || !isLoadFirstTime) {
                setMessagesToCompare(messagesOtherUserOnly)
                setIsLoadFirstTime(true)
                return
            }
            let newMessage: number = 0
            for (const conversationId of Object.keys(messagesOtherUserOnly)) {
                if ((messagesOtherUserOnly[conversationId].messages?.length || 0) > (messagesToCompare[conversationId]?.messages?.length || 0)) {
                    let chatId = searchParams.get(`id`);
                    if (!path.includes("messages") && (!path.includes(`chat`) && (!chatId && chatId !== conversationId))) {
                        newMessage++
                    }
                }
            }
            setMessageNotifications((prev) => (newMessage > prev ? newMessage : prev))
            // setMessagesToCompare(messagesOtherUserOnly)
        } catch (error) {
            console.log(error)
        }
    }

    const filterUserContextChats = (chats: chatsProps): chatsProps => {
        return Object.keys(chats).reduce((filteredChats, chatId) => {
            const chat = chats[chatId];
            if (chat.context === "user" || chat?.isBulletinBoard) {
                filteredChats[chatId] = chat;
            }
            return filteredChats;
        }, {} as chatsProps);
    }

    const filterUserMessages = (chats: chatsProps): chatsProps => {
        return Object.keys(chats).reduce((filteredChats, chatId) => {
            const chat = chats[chatId];
            if (chat.messages && chat.messages.length) {
                filteredChats[chatId] = filteredChats[chatId] || { ...chat, messages: [] };
                filteredChats[chatId].messages = chat.messages.filter(f => {
                    if (f.from !== currentUser.id) {
                        if (f.from === "RAI" && typeof f.data.content === "string" && f.data.role === "assistant") {
                            return true;
                        }
                        if (f.from === "RAI") {
                            return false;
                        }
                        return true;
                    }
                    return false;
                });
            }
            return filteredChats;
        }, {} as chatsProps);
    };

    const waitForSeconds = (seconds: number): Promise<void> => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, seconds * 1000);
        });
    };

    return (
        <AllNotificationContext.Provider value={{ allNotifications, messageNotifications, connectionNotifications, needsNotifications }}>
            {children}
        </AllNotificationContext.Provider>
    );
};
